import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ApplicationRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { SynchronizationService } from '../../services/synchronization.service';
import { OfflineService } from 'src/app/polls/services/offline.service';
import { ClientsService } from 'src/app/polls/services/clients.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { StatsService } from 'src/app/polls/services/stats.service';
import { Category } from 'src/app/polls/models/category.models';
import { ClientAllComponent } from 'src/app/polls/components/client-all/client-all.component';
import { FiltersMenuInClientsComponent } from 'src/app/polls/components/filters-menu-in-clients/filters-menu-in-clients.component';
import Swal from 'sweetalert2';
import { auth } from 'src/environments/environment';
import { Observable, Subscription, of } from 'rxjs';
import { SyncService } from 'src/app/polls/services/sync.service';

@Component({
  selector: 'app-synchronization',
  templateUrl: './synchronization.component.html',
  styleUrls: ['./synchronization.component.scss'],
})
export class SynchronizationComponent implements OnInit, OnDestroy {
  private API_URL = `${auth.urlConfig}`;
  public offlineStatus: string = 'no';
  public clients: any[] = [];
  public polls: any[] = [];
  //public clientsWithPolls: any[] = [];
  clientsWithPolls$: Observable<any> = of([]);
  public offlineSyncPollsCount: number = 0;
  public progressSteps: string = '';
  public synchronizationProgress: number = 0;
  public synchronizationInProgress: boolean = false;
  public stopProgress: number = 0;
  public stopProgressSteps: string = '';
  public stopInProgress: boolean = false;
  public uploadResponses: boolean = false;
  public uploadMessage: string = '';
  private stopObservable: any;
  private runningObservable: any;
  private uploadObservable: any;
  private readonly user: any = null;
  listOptionsVendedor: Category[] = [];
  offlineActive: boolean = false;
  clientAllComponentChild: ClientAllComponent;
  isOfflineMode: boolean = false;
  offlineHeader: any;
  isFilters: boolean = false;
  numberFilters: number = 0;
  syncPollsCount$: Observable<number>;
  isStatusSync: boolean = false;

  progressText: string = 'Descargando Clientes';
  progressValue: number = 0;

  firstTime: boolean = false;

  private syncSubscription: Subscription;

  @Output() openNavSelectRouteEvent = new EventEmitter();
  @Output() closeNavSelectRouteEvent = new EventEmitter();

  @ViewChild('sidenavSelectRoute') sidenavSelectRoute: any;
  @ViewChild(FiltersMenuInClientsComponent, { static: false })
  filtersMenuInClientsComponent: FiltersMenuInClientsComponent;

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    localStorage.setItem('refreshAttempted', 'true');
  }

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private readonly synchronizationService: SynchronizationService,
    private readonly offlineService: OfflineService,
    private readonly clientsService: ClientsService,
    private analytics: AngularFireAnalytics,
    private readonly statsService: StatsService,
    private appRef: ApplicationRef,
    private syncService: SyncService
  ) {
    this.syncPollsCount$ = this.synchronizationService.getUpdateCount();
    this.user = jwt_decode(localStorage.getItem('token')).user;

    this.synchronizationService.getIsRunningObservable().subscribe((data) => {
      this.progressSteps = data.step;
    });

    this.stopObservable = this.synchronizationService
      .getStopIsRunningObservable()
      .subscribe((data: { status: boolean; step: string }) => {
        this.stopInProgress = data.status;
        this.stopProgressSteps = data.step;

        if (data.status) this.spinner.show('upload-spinner');
        else {
          this.spinner.hide('upload-spinner');

          this.ngOnInit();
        }
      });
  }

  ngOnDestroy(): void {
    this.stopObservable?.unsubscribe();
    this.runningObservable?.unsubscribe();
    this.uploadObservable?.unsubscribe();
    this.syncSubscription?.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.syncSubscription = this.syncService.initVerification().subscribe();

    await this.offlineService.getOfflineHeader(1, this.user.id).then((data) => {
      this.isStatusSync = data?.statusSync === 'good' ? true : false;
    });
    this.syncPollsCount$.subscribe((count) => {
      if (count === 0) {
        this.clientsWithPolls$ = of([]);
      }
    });

    if (localStorage.getItem('refreshAttempted') === 'true') {
      this.router.navigateByUrl('/poll');
      localStorage.setItem('refreshAttempted', 'false');
    }

    this.offlineStatus = localStorage.getItem('isOfflineMode');

    await this.loadSyncPollsCount();

    this.offlineService.loadOfflineHeader().then(() => {
      this.offlineService
        .getOfflineHeader(1, this.user.id)
        .then((offlineHeader) => {
          this.offlineHeader = offlineHeader ? offlineHeader : null;
          this.isOfflineMode = offlineHeader ? offlineHeader.status : false;
          this.offlineActive = this.isOfflineMode;
        });
    });
  }

  async isGoodConecction() {
    return new Promise<any>(async (resolve) => {
      let speedMbps;
      let sumSpeed = 0;
      for (let step = 0; step < 5; step++) {
        speedMbps = await this.offlineService.testConection();
        if (Number(speedMbps.duration) >= 20) {
          // Detenemos si alguna consulta supera los 20 segundos
          resolve(false);
          break;
        }
        sumSpeed += speedMbps.duration;
      }
      const promedio = Number(sumSpeed) / 5;
      resolve(promedio > 3 ? false : true);
    });
  }

  configSync() {
    this.sidenavSelectRoute.open();
  }

  runOfflineDeactive() {
    let message = 'Esta seguro(a) de realizar esta acción?';
    if (this.offlineHeader?.statusSync === 'unsynchronized') {
      message =
        'Tienes encuestas sin sincronizar, se va proceder a sincronizar y luego a desactivar, esta seguro(a) de realizar esta acción?';
    }
    Swal.fire({
      title: 'Desactivación de modo offline',
      html: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (this.offlineHeader?.statusSync === 'unsynchronized') {
          this.synchronizationService.nextSynchronizationPolls({
            deactiveOffline: true,
          });
          this.offlineActive = false;
          this.offlineStatus = 'no';
          localStorage.setItem('isOfflineMode', this.offlineStatus);
          return;
        }

        this.deactiveOffline();
      } else {
        this.offlineActive = true;
        this.offlineStatus = 'yes';
        localStorage.setItem('isOfflineMode', this.offlineStatus);
      }
    });
  }

  async deactiveOffline() {
    this.analytics.logEvent(`desactivacion_sin_conexion`, {
      usuario: this.user.email,
    });
    this.spinner.show('loading-clients');
    this.isFilters = false;
    this.numberFilters = 0;

    await this.offlineService.deactiveOffline();

    this.synchronizationService.nextStopIsRunning({
      status: false,
      step: '',
    });

    setTimeout(() => {
      this.ngOnInit();
    }, 100);

    localStorage.removeItem('filterClientsAllData');
    this.closeNavSelectRouteEvent.emit({ deleteSelected: true });
    this.appRef.tick();
  }

  async setListOptionsVendedor($event) {
    this.listOptionsVendedor = $event;
  }

  async closeNav({ deleteSelected = false }) {
    this.sidenavSelectRoute.close();
    await this.offlineService.loadOfflineHeader();
    const offlineHeader: any = await this.offlineService.getOfflineHeader(
      1,
      this.user.id
    );
    this.offlineActive = offlineHeader ? offlineHeader.status : false;
    setTimeout(() => {
      this.clientAllComponentChild?.ngOnInit(true);
      if (deleteSelected) this.filtersMenuInClientsComponent?.deleteSelected();
    }, 500);

    this.isOfflineMode = this.offlineActive;
  }

  async loadSyncPollsCount() {
    const syncPolls = await this.offlineService.getSyncPolls();
    const responses = await this.offlineService.getResponses();
    let pollsCount: number = 0;

    const organizedClients = syncPolls
      .filter(
        (sp) =>
          sp.status === 'unsynchronized-edit' ||
          sp.status === 'unsynchronized' ||
          sp.status === 'sync-responses-completed' ||
          sp.status === 'sync-close-response' ||
          sp.status === 'sync-completed'
      )
      ?.reduce((acc, client) => {
        if (acc?.[client.client.cliente_id]) return acc;

        client.client.polls = client.client.polls
          .map((poll) => {
            const pollResponses = responses?.filter(
              (res) =>
                res.poll_id == poll.id && res.client_id == client.client.id
            );

            if (!Boolean(pollResponses.length)) return poll;

            pollsCount += 1;

            return {
              ...poll,
              status: client.status,
              date_init: client.date_init,
              date_finish: client.date_finish,
              responses: pollResponses,
            };
          })
          .filter((poll) => Boolean(poll?.responses?.length));

        return {
          ...acc,
          [client.client.cliente_id]: {
            ...client.client,
            id: client.id,
            user_id: client.user_id,
          },
        };
      }, []);

    this.clientsWithPolls$ = of(Object.values(organizedClients));
    this.offlineSyncPollsCount = pollsCount;
  }

  async synchronize() {
    this.stopProgressSteps = 'Sincronizando encuestas completadas.';
    this.spinner.show('upload-spinner');
    this.uploadResponses = true;

    this.synchronizationService.nextSynchronizationPolls({});

    this.synchronizationService
      .getUploadIsRunningObservable()
      .subscribe((status) => {
        if (!status) {
          this.spinner.hide('upload-spinner');
          this.uploadResponses = false;

          this.ngOnInit();
        }
      });
  }

  async _synchonizationProcess() {
    this.progressSteps = 'Sincronizando clientes.';

    this.clientsService
      .getClientsAndPollsBySellers([])
      .subscribe(async (data) => {
        this.analytics.logEvent(`activacion_sin_conexion`, {
          usuario: this.user.email,
          method: 'all',
        });

        await this.offlineService.setClients(data.clients);

        this.progressSteps = 'Sincronizando tipos de sku.';
        const enterprises = JSON.parse(
          localStorage.getItem('enterprises')
        )?.map((e) => e.id);
        const customerSku = await this.clientsService
          .getAllSkus(enterprises)
          .toPromise();

        await this.offlineService.setCustomersSku(customerSku['customersSku']);

        this.progressSteps = 'Sincronizando secciones.';

        const polls: any = await this.offlineService.getSectionsPoll(
          data.polls
        );

        await this.offlineService.setPolls(polls);

        this.progressSteps = 'Sincronizando categorias.';

        await this.offlineService.downloadCategories([]);

        await this.offlineService.clearPollStats();

        this.progressSteps = 'Sincronizando estadisticas.';

        await this._manageStats(data.polls);

        await this.offlineService.setOfflineHeader({
          id: 1,
          user_id: this.user.id,
          date: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
          number_clients: data.clients.length,
          number_polls: polls.length,
          status: true,
          mode: 'syncAll',
          from: 'clients',
          sellers: [],
          dataFilters: {
            enterpriseId: null,
            filters: [],
            isFilter: false,
            keepFilter: false,
          },
          dataFiltersPoll: [],
        });

        this.progressSteps = 'Finalizando.';

        setTimeout(() => {
          this.synchronizationInProgress = false;
          this.spinner.hide('upload-spinner');
        }, 1000);
      });
  }

  async _manageStats(clientPollsData) {
    const promises = [];

    for (const poll of clientPollsData) {
      promises.push(
        this.statsService
          .getStatsByPollPeriodGoals(poll.id, [])
          .toPromise()
          .then(async (data) => {
            data['user_id'] = this.user.id;
            data['id'] = `${poll.id}-${this.user.id}`;

            await this.offlineService.setPollStats(data);
          })
      );
    }

    await Promise.all(promises);
  }

  handleProgressChange(event) {
    this.progressValue = event;
  }
  progressTextChange(event) {
    this.progressText = event;
  }
  progressSpinnerChange(event) {
    event
      ? this.spinner.show('synchronizing')
      : this.spinner.hide('synchronizing');
  }
}
