import Dexie, { Table } from 'dexie';
import { promise } from 'protractor';
import * as _moment from 'moment';
import { log } from 'console';
const moment = _moment;

export interface OfflineHeader {
  id?: number;
  user_id: number;
  date: string;
  token: string;
  number_clients: number;
  number_polls: number;
  status: boolean;
  statusSync: string;
  mode: string;
  from: string;
  data: Array<any>;
  dataFilters: any;
  dataFiltersPoll: any;
  lastVerificationDateTime: string;
}

export interface BackSyncHeader {
  id?: number;
  user: any;
  token: string;
  status: string;
  api_url: string;
}
export interface Polls {
  id?: number;
  name: string;
  status: string;
  period_id?: number;
  expire_date?: string;
  segmentos?: string;
  ubicacion?: string;
  description?: string;
  publish_date?: string;
  countquestion?: number;
  geolocation?: string;
  minutes?: string;
  infoextra?: boolean;
  infoextrapoll?: string;
  infoextracustomer?: string;
  showmessageinit?: boolean;
  addgoals?: boolean;
  measuring_period?: number;
}

export interface Stats {
  id?: number;
  data: any;
}

export interface Clients {
  id?: number;
  cliente_id: string;
  descr_cliente: string;
  descr_corta_comuna: string;
  descr_segmento: any;
  segmento: any;
  descr_relevancia: any;
  relevancia: any;
  descr_nivel: any;
  nivel: any;
  jefe_ventas_id: any;
  subgerente_id: any;
  gerente_id: any;
  oficina_comercial_id: any;
  orden_ofcom_venta: any;
  orden_distrito_venta: any;
  orden_zona_venta: any;
  descr_vendedor: string;
  vendedor_id: any;
  empresa_id: any;
  direccion: string;
  status: boolean;
  polls: Array<any>;
  searchstr: string;
  messageWords: any;
  flag: string;
  georeferencia_x: string;
  georeferencia_y: string;
  edit: string;
}

export interface ClientsCompleted {
  id?: number;
  cliente_id: string;
  descr_cliente: string;
  descr_corta_comuna: string;
  descr_segmento: string;
  descr_relevancia: string;
  descr_nivel: string;
  jefe_ventas_id: any;
  subgerente_id: any;
  gerente_id: any;
  oficina_comercial_id: any;
  orden_distrito_venta: string;
  orden_zona_venta: string;
  descr_vendedor: string;
  vendedor_id: any;
  direccion: string;
  status: boolean;
  poll_id: number;
  searchStr: string;
  date_finish: string;
}

export interface Syncpolls {
  id?: string;
  poll_id: number;
  period_id: number;
  client: any;
  status: string;
  coords_init: any;
  params: any;
  coords_finish: any;
  descr_segmento?: string;
  descr_relevancia?: string;
  descr_nivel?: string;
  descr_vendedor?: string;
  descr_jefe_ventas?: string;
  descr_subgerente?: string;
  descr_gerente?: string;
  descr_ofcom_venta?: string;
  descr_distrito_venta?: string;
  descr_zona_venta?: string;
  date_init?: string;
  date_init_save?: string;
  date_finish?: string;
  date_finish_save?: string;
  user_id: number;
}

export interface Responses {
  id?: string;
  sync_poll_id: string;
  poll_id: number;
  client_id: number;
  type_save: string;
  service: string;
  data: any;
  type_question: any;
  question_title: string;
  type_desc: string;
}

export interface Files {
  id?: string;
  poll_id: number;
  sync_poll_id: string;
  data: any;
}

export interface Targetpoll {
  id?: string;
  categories: any;
  user_id: number;
}

export interface Categories {
  id?: string;
  categories: Array<any>;
}
export interface StatsPoll {
  id?: number;
  poll_id: number;
  user_id: number;
  pollByDay: Array<any>;
  initDate?: string;
  endDate: string;
  daysElapsed: number;
  daysremaining: number;
  dataPollGoals: Array<any>;
  total: number;
  totalInit: number;
  totalToday: number;
  totalDays: number;
  totalFinit: number;
}

export class AppDB extends Dexie {
  offlineHeader!: Table<OfflineHeader, any>;
  backSyncHeader!: Table<BackSyncHeader, any>;
  polls!: Table<Polls, Array<any>>;
  stats!: Table<Stats, Array<any>>;
  clients!: Table<Clients, Array<any>>;
  clientsCompleted!: Table<ClientsCompleted, Array<any>>;
  syncpolls!: Table<Syncpolls, Array<any>>;
  responses!: Table<Responses, any>;
  files!: Table<Files, any>;
  targetpoll!: Table<Targetpoll, any>;
  categories!: Table<Categories, any>;
  statsPolls!: Table<StatsPoll, any>;
  customersSku!: Table<any, Array<any>>;

  constructor() {
    super('ngdexieliveQuery');
    this.version(17).stores({
      offlineHeader:
        '++id, user_id,[id+user_id], date, number_clients, number_polls, status, statusSync, mode, from, data, dataFilters, dataFiltersPoll,lastVerificationDateTime',
      backSyncHeader: '++id, user, token, status, api_url',
      polls:
        '++id, name, status, period_id, expire_date, segmentos, ubicacion, description, publish_date, countquestion, geolocation, minutes, infoextra, infoextrapoll, infoextracustomer, showmessageinit,  addgoals, measuring_period',
      stats: '++id, data',
      clients:
        '++id, cliente_id, descr_cliente, descr_corta_comuna, relevancia, descr_segmento, segmento, descr_relevancia, descr_nivel, nivel, jefe_ventas_id, subgerente_id, gerente_id, oficina_comercial_id, orden_ofcom_venta, orden_distrito_venta, orden_zona_venta, descr_vendedor, vendedor_id, direccion, searchstr, status, empresa_id, *messageWords, [segmento+relevancia+nivel+vendedor_id+jefe_ventas_id+subgerente_id+gerente_id+orden_ofcom_venta+orden_zona_venta], flag, georeferencia_x, georeferencia_y',
      clientsCompleted:
        '++id, cliente_id, descr_cliente, descr_corta_comuna, descr_segmento, descr_relevancia, descr_nivel, jefe_ventas_id, subgerente_id, gerente_id, oficina_comercial_id, orden_distrito_venta, orden_zona_venta, descr_vendedor, [poll_id+descr_vendedor] , vendedor_id, [poll_id+vendedor_id], direccion, poll_id, searchstr, status,date_finish,[poll_id+date_finish]',
      syncpolls:
        '++id, poll_id, period_id, client, status, coords_init, params, coords_finish,[poll_id+status], descr_segmento,[poll_id+descr_segmento], descr_relevancia,[poll_id+descr_relevancia], descr_nivel,[poll_id+descr_nivel],descr_vendedor,[poll_id+descr_vendedor], descr_jefe_ventas,[poll_id+descr_jefe_ventas],descr_subgerente,[poll_id+descr_subgerente],descr_gerente,[poll_id+descr_gerente],descr_ofcom_venta,[poll_id+descr_ofcom_venta],descr_distrito_venta,[poll_id+descr_distrito_venta],descr_zona_venta,[poll_id+descr_zona_venta], date_init, date_init_save, date_finish, date_finish_save,[poll_id+date_finish],user_id,[status+poll_id+user_id]',
      responses:
        '++id, sync_poll_id, poll_id, client_id, type_save, service, data, type_question, question_title, type_desc',
      files: '++id, poll_id, data, sync_poll_id',
      targetpoll: '++id, categories,user_id,[id+user_id]',
      categories: '++id, categories',
      customersSku: '++id, skutype_id, client_id',
      statsPolls:
        '++id,[poll_id+user_id],poll_id,user_id,pollByDay, initDate, endDate, daysElapsed, daysremaining, dataPollGoals, total, totalInit, totalToday, totalDays,totalFinit',
    });

    let versionDB = Dexie.semVer;
    console.log('Dexie Version: ', versionDB);
    this.open()
      .then()
      .catch(Dexie.VersionChangeError, function (e) {
        // Failed with VersionChangeError
        console.error('VersionChange error: ' + e.message);
      })
      .catch(Error, function (e) {
        console.error('CONTROLANDO EL ERROR: ' + e.message);
      })
      .catch(function (e) {
        // Other error such as a string was thrown
        console.error('OTRO ERROR: ' + e);
      });
    this.clients.hook('creating', function (primKey, obj, transaction) {
      function getAllWords(text) {
        /// <param name="text" type="String"></param>
        var allWordsIncludingDups = text.split(' ');
        var allWordsReOrder = [];
        var aux = '';
        for (let k = 0; k < allWordsIncludingDups.length; k++) {
          for (let j = k; j < allWordsIncludingDups.length; j++) {
            for (let i = k; i < j + 1; i++) {
              if (aux == '') {
                aux = allWordsIncludingDups[i];
              } else {
                aux = aux + ' ' + allWordsIncludingDups[i];
              }
            }
            allWordsReOrder.push(aux);
            aux = '';
          }
        }

        var wordSet = allWordsReOrder.reduce(function (prev, current) {
          prev[current] = true;
          return prev;
        }, {});
        return Object.keys(wordSet);
      }
      if (typeof obj.searchstr == 'string')
        obj.messageWords = getAllWords(obj.searchstr);
      if (obj.polls.length > 0) {
        obj.polls.forEach((poll) => {
          poll.edit = poll.edit.split(',')[1];
        });
      }
      obj.flag = 'y';
    });
  }

  async resetDatabase() {
    await db.transaction(
      'rw',
      'polls',
      'clients',
      'stats',
      'responses',
      'targetpoll',
      () => {
        this.polls.clear();
        this.stats.clear();
        this.clients.clear();
        this.clientsCompleted.clear();
        this.syncpolls.clear();
        this.responses.clear();
        this.files.clear();
        this.targetpoll.clear();
        this.statsPolls.clear();
        // this.populate();
      }
    );
  }

  async getSectionDB(poll_id, section_id) {
    return new Promise<void>(async (resolve, reject) => {
      const poll: any = await db.polls.get(poll_id);
      if (poll) {
        console.log('poll', poll);
        const section = poll.sections.find(
          (section) => section.id === section_id
        );
        resolve(section);
      } else {
        reject();
      }
    });
  }

  async setPoll(dataPoll) {
    return new Promise<void>(async (resolve, reject) => {
      // const last_updated_date = moment().add(-1, 'days').format('DD-MM-YYYY');
      const last_updated_date = moment().format('DD-MM-YYYY');
      const { id, data = null, status = 'sync-downloaded' } = dataPoll;
      const syncPollData = await db.polls.get(id);
      if (!syncPollData) {
        /* const result: any = await db.polls.add({
          id,
          data,
          status,
          last_updated_date,
        }); */
        resolve();
      } else {
        let payload: any = {
          status,
          last_updated_date,
        };
        if (data) {
          payload.data = data;
        }
        const result: any = await db.polls.update(id, payload);
        resolve(result);
      }
    });
  }

  async setTargetpoll(data) {
    return new Promise<void>(async (resolve, reject) => {
      const { id, categories, user_id } = data;
      const targetpollData = await db.targetpoll.get(id);
      if (!targetpollData) {
        const result: any = await db.targetpoll.add({
          id,
          categories,
          user_id,
        });
        resolve(result);
      } else {
        const result: any = await db.targetpoll.update(id, categories);
        resolve(result);
      }
    });
  }

  async setStast(statsDataParams) {
    return new Promise<void>(async (resolve, reject) => {
      const { id, data } = statsDataParams;
      const statsData = await db.stats.get(id);
      if (!statsData) {
        const result: any = await db.stats.add({ id, data });
        resolve(result);
      } else {
        const result: any = await db.stats.update(id, data);
        resolve(result);
      }
    });
  }

  async setSyncPoll(data) {
    return new Promise<void>(async (resolve, reject) => {
      const {
        id,
        poll_id,
        period_id,
        client,
        status,
        coords_init,
        params,
        coords_finish,
        descr_segmento,
        descr_relevancia,
        descr_nivel,
        descr_vendedor,
        descr_jefe_ventas,
        descr_subgerente,
        descr_gerente,
        descr_ofcom_venta,
        descr_distrito_venta,
        descr_zona_venta,
        date_init,
        date_init_save,
        date_finish,
        date_finish_save,
        user_id,
      } = data;

      const syncPollData = await db.syncpolls.get(id);
      if (!syncPollData) {
        const result: any = await db.syncpolls.add({
          id,
          poll_id,
          period_id,
          client,
          status,
          coords_init,
          params,
          coords_finish,
          descr_segmento,
          descr_relevancia,
          descr_nivel,
          descr_vendedor,
          descr_jefe_ventas,
          descr_subgerente,
          descr_gerente,
          descr_ofcom_venta,
          descr_distrito_venta,
          descr_zona_venta,
          date_init,
          date_init_save,
          date_finish,
          date_finish_save,
          user_id,
        });
        resolve(result);
      } else {
        let paramsData: any = {
          status,
        };
        if (coords_finish) {
          paramsData.coords_finish = coords_finish;
        }
        if (params) {
          paramsData.params = params;
        }

        if (date_init) {
          paramsData.date_init = date_init;
        }

        if (date_init_save) {
          paramsData.date_init_save = date_init_save;
        }

        if (date_finish) {
          paramsData.date_finish = date_finish;
        }

        if (date_finish_save) {
          paramsData.date_finish_save = date_finish_save;
        }

        if (user_id) {
          paramsData.user_id = user_id;
        }

        const result: any = await db.syncpolls.update(id, paramsData);
        resolve(result);
      }
    });
  }

  async getQuestionResponseDB(id) {
    return new Promise<any>(async (resolve) => {
      const result = await db.responses.get(id);
      resolve(result);
    });
  }

  async setResponse(response: any) {
    return new Promise<void>(async (resolve, reject) => {
      db.getQuestionResponseDB(response.id).then(async (questionData) => {
        if (!questionData) {
          const result: any = await db.responses.add(response);
          resolve(result);
        } else {
          const result: any = await db.responses.update(response.id, {
            data: response.data,
          });
          resolve(result);
        }
      });
    });
  }

  async setFile(file: any) {
    return new Promise<void>(async (resolve, reject) => {
      db.files.get(file.id).then(async (fileData) => {
        if (!fileData) {
          const result: any = await db.files.add(file);
          resolve(result);
        } else {
          const result: any = await db.files.update(file.id, {
            data: file.data,
          });
          resolve(result);
        }
      });
    });
  }

  async deleteFile(id: any) {
    return new Promise<void>(async (resolve, reject) => {
      db.files.get(id).then(async (fileData) => {
        const result: any = await db.files.delete(id);
        resolve(result);
      });
    });
  }
  async setStatsPolls(data: StatsPoll) {
    return new Promise<void>(async (resolve, reject) => {
      const { id } = data;
      const statsData = await db.statsPolls.get(id);
      if (!statsData) {
        const result: any = await db.statsPolls.add(data);
        resolve(result);
      } else {
        const result: any = await db.statsPolls.update(id, data);
        resolve(result);
      }
    });
  }

  async getCustomerSkuDB(skutypeId: any, clientId: any) {
    try {
      const allClients = await db.customersSku.toArray();
      const customerClient = allClients.find(
        (c) =>
          c.client_id == parseInt(clientId) &&
          c.skutype_id == parseInt(skutypeId)
      );
      console.log('');
      return customerClient;
    } catch (e) {
      console.log('Error en getCustomerSkuDB: ', e);
      throw e;
    }
  }
}

export const db = new AppDB();
