<mat-tab-group
  class="bottom-navbar tab-container"
  animationDuration="0ms"
  [(selectedIndex)]="selectedTabIndex"
>
  <mat-tab *ngIf="showTab">
    <ng-template mat-tab-label>
      <div (click)="onChangeTab($event, 0)" class="icon-container">
        <mat-icon>home</mat-icon>
        <span>Inicio</span>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab (click)="onChangeTab($event, 1)">
    <ng-template mat-tab-label>
      <div class="icon-container" routerLink="/poll" routerLinkActive="active">
        <mat-icon [class.active]="selectedTabIndex === 1"
          >supervisor_account</mat-icon
        >
        <span [class.active]="selectedTabIndex === 1">Clientes</span>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab (click)="onChangeTab($event, 2)">
    <ng-template mat-tab-label>
      <div
        class="icon-container"
        routerLink="/synchronization"
        routerLinkActive="active"
      >
        <mat-icon [class.active]="selectedTabIndex === 2">sync</mat-icon>

        <div
          [ngClass]="{ 'right-20': !showTab, 'right-10': showTab }"
          class="badge-sync"
        >
          {{ syncPollsCount$ | async }}
        </div>

        <span [class.active]="selectedTabIndex === 2">Sync</span>
      </div>
    </ng-template>
  </mat-tab>

  <mat-tab (click)="onChangeTab($event, 3)">
    <ng-template mat-tab-label>
      <div
        class="icon-container"
        routerLink="/published"
        routerLinkActive="active"
      >
        <mat-icon [class.active]="selectedTabIndex === 3">ballot</mat-icon>

        <span [class.active]="selectedTabIndex === 3">Encuestas</span>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="showTab">
    <ng-template mat-tab-label>
      <div
        (click)="onChangeTab($event, 4)"
        class="icon-container"
        routerLinkActive="active"
      >
        <mat-icon [class.active]="selectedTabIndex === 4">task_alt</mat-icon>
        <span [class.active]="selectedTabIndex === 4">Tareas</span>
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
