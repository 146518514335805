import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HttpResponse, HttpEventType, HttpClient } from '@angular/common/http';
import { PollModel } from '../../models/poll.model';
import { PollsService } from '../../services/polls.service';
import { OfflineService } from '../../services/offline.service';
import { ClientsService } from '../../services/clients.service';
import * as jwt_decode from 'jwt-decode';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { SectionsService } from 'src/app/polls/services/sections.service';
import { debounceTime, delay, map, startWith } from 'rxjs/operators';
import { ResponseService } from 'src/app/polls/services/response.service';
import { UploadService } from 'src/app/polls/services/upload.service';
import { ImagesService } from 'src/app/polls/services/images.service';
import { CategoryTargetService } from 'src/app/polls/services/category-target.service';
import Swal from 'sweetalert2';
import { auth } from 'src/environments/environment';
import { dev } from '../../../../environments/environment';
// Dexie DB
import { db } from 'src/app/db/db';
import { StatsService } from '../../services/stats.service';
import * as _moment from 'moment';
const moment = _moment;
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { UserModel } from '../../models/user.model';
import { ClientModel } from '../../models/client.model';
import { ClientAllComponent } from '../client-all/client-all.component';
import { formatDate } from '@angular/common';
import * as Sentry from '@sentry/angular-ivy';

// No Sleep
import NoSleep from 'nosleep.js';

interface SyncManager {
  getTags(): Promise<string[]>;
  register(tag: string): Promise<void>;
}

declare global {
  interface ServiceWorkerRegistration {
    readonly sync: SyncManager;
  }

  /* interface SyncEvent extends ExtendableEvent {
    readonly lastChance: boolean;
    readonly tag: string;
  } */

  /* interface ServiceWorkerGlobalScopeEventMap {
    sync: SyncEvent;
  } */
}
import { EnterpriseModel } from '../../models/enterprise';
import { FiltersMenuInClientsComponent } from '../filters-menu-in-clients/filters-menu-in-clients.component';
import { Category } from '../../models/category.models';
import { SynchronizationService } from 'src/app/synchronization/services/synchronization.service';
import { SyncService } from '../../services/sync.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-polls',
  templateUrl: './polls.component.html',
  styleUrls: ['./polls.component.css'],
})
export class PollsComponent implements OnInit, AfterViewInit, OnDestroy {
  private API_URL = `${auth.urlConfig}`;
  private nosleep = new NoSleep();
  search: String;
  selected: String = '';
  polls: PollModel[];
  countPolls: Number = 0;
  countClients: Number = 0;
  pollsDraft: PollModel[];
  countDraft: number = 0;
  poll: PollModel;
  pollFilter: number;
  loading: boolean = false;
  offlinePolls: Array<any> = [];
  isOfflineMode: boolean = false;
  controlReset: number = 0;
  syncPollsCompleted: Array<any> = [];
  syncPollsIncomplete: Array<any> = [];
  syncPolls: Array<any> = [];
  pollByStats: Array<any> = [];

  isUpload: boolean = false;
  offlineActive: boolean = false;
  controlLoopSyncPoll: number = 0;
  controlLoopSyncRes: number = 0;
  progressUploadPollValue: number = 0;
  progressUploadPollText: string = 'Status General';
  progressUploadResValue: number = 0;
  progressUploadResText: string = 'Sincronizando Respuestas';
  uploadPollClient: string = '';
  uploadPoll: string = '';
  uploadResponse: string = '';
  selectedTabIndex: number = 0;
  listOptionsVendedor: Category[] = [];

  lat1: any = 0;
  long1: any = 0;
  lat2: any = 0;
  long2: any = 0;
  error: any = '';
  devenv: String;
  userId: number;
  user: UserModel;
  decodedToken: any;
  client: ClientModel;
  @ViewChild(MatDrawer) sidenav!: MatDrawer;
  @ViewChild('sidenavFiltersMenuInClients') sidenavFiltersMenuInClients: any;
  @ViewChild('sidenavSelectRoute') sidenavSelectRoute: any;
  enterprises: EnterpriseModel[];
  showCustomerList: boolean = true;
  @ViewChild(ClientAllComponent, { static: false })
  clientAllComponentChild: ClientAllComponent;
  @ViewChild(FiltersMenuInClientsComponent, { static: false })
  filtersMenuInClientsComponent: FiltersMenuInClientsComponent;
  dataInitPollClientAll: any = {
    initPoll: false,
    deactiveOffline: false,
    data: [],
  };
  messagembs: string = '0.00 mbps - 0.00 seg';
  statusBackSyncApi: string = 'Waiting';
  private pollReviewObservable: any;
  private synchronizationObservable: any;
  private syncSubscription: Subscription;
  public environment = dev.env;
  firstTime: boolean = false;

  progressText: string = 'Descargando Clientes';
  progressValue: number = 0;

  isReconfiguration: boolean = false;

  constructor(
    private categoryTargetService: CategoryTargetService,
    private imagesService: ImagesService,
    private pollsService: PollsService,
    private offlineService: OfflineService,
    private clientsService: ClientsService,
    private uploadService: UploadService,
    private sectionsService: SectionsService,
    private responseService: ResponseService,
    private statsService: StatsService,
    private syncService: SyncService,
    private router: Router,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private http: HttpClient,
    private analytics: AngularFireAnalytics,
    private route: ActivatedRoute,
    private synchronizationService: SynchronizationService
  ) {
    this.synchronizationService
      .getSynchronizationPollsObservable()
      .subscribe((data) => {
        this.runPollSync(data);
      });

    this.synchronizationService.getPollPreviewObservable().subscribe((data) => {
      const { poll, preview } = data;

      this.getPoll(poll, preview);
    });

    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      this.isReconfiguration = navigation.extras.state['isReconfiguration'];
    }
  }

  ngOnDestroy(): void {
    this.syncSubscription?.unsubscribe();
    this.pollReviewObservable?.unsubscribe();
    this.synchronizationObservable?.unsubscribe();
  }

  handleProgressChange(event) {
    this.progressValue = event;
  }
  progressTextChange(event) {
    this.progressText = event;
    if (event === 'Completado') {
      this.firstTime = false;
    }
  }
  progressSpinnerChange(event) {
    event
      ? this.spinner.show('synchronizing')
      : this.spinner.hide('synchronizing');
  }

  async ngOnInit() {
    const decodedToken = jwt_decode(localStorage.getItem('token'));
    this.user = decodedToken.user;

    await this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.firstTime = offlineHeader
          ? false
          : offlineHeader?.lastVerificationDateTime !== undefined;
      });

    if (this.firstTime) {
      this.syncSubscription = this.syncService.initVerification().subscribe();
    }

    this.offlineService.startMonitoringStatusSync();
    this.route.queryParams.subscribe((data) => {
      if (Object.keys(data)?.length) this.router.navigate(['/poll']);
    });

    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        this.isOfflineMode =
          offlineHeader && offlineHeader.status ? true : false;
        // this.nosleep.enable();
        const token = localStorage.getItem('token');
        this.decodedToken = jwt_decode(token);
        this.user = this.decodedToken.user;
        this.userId = this.user.id;
        this.devenv = dev.env;
        this.countPolls = 0;
        this.countDraft = 0;
        this.pollFilter = Number(localStorage.getItem('filterPoll'));
        this.fetchPolls();

        this.spinner.show('loading');

        this.offlineService.loadOfflineHeader();
        this.enterprises = JSON.parse(localStorage.getItem('enterprises'));

        if (this.isOfflineMode) {
          if (this.route.snapshot.queryParamMap.get('isToSync')) {
            Swal.fire({
              title: 'Notificación',
              html: 'Se procedera a sincronizar las encuestas, esta de acuerdo?',
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              this.router.navigate([], {
                queryParams: {
                  isToSync: null,
                },
                queryParamsHandling: 'merge',
              });
              if (result.isConfirmed) {
                this.runPollSync({});
                // this.initPoll(data);
              }
            });
          }
        }

        this.spinner.hide('loading');
      });
    this.runBackgroundSync('webworker');
  }

  async testConection() {
    const speedMbps = await this.offlineService.testConection();
    this.messagembs = `${speedMbps.speed} mbps - ${speedMbps.duration} seg`;
  }

  async runBackgroundSync(option = 'backsynapi') {
    await this.offlineService.setBackSyncHeader({
      id: 1,
      user: this.user,
      token: localStorage.getItem('token'),
      status: 'running',
      api_url: this.API_URL,
    });
    if (option === 'webworker') {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker(
          new URL('src/app/web-workers/background-sync.worker', import.meta.url)
        );
        worker.onmessage = ({ data }) => {};
        worker.postMessage({ isAllPolls: true });
      } else {
        console.log('Web workers are not supported in this environment.');
      }
    } else {
      await this.offlineService.runBackgroundSync();
    }
  }

  disableSleep() {
    this.nosleep.disable();
    // alert('No Sleep disabled');
  }

  async ngAfterViewInit() {
    await this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.firstTime = offlineHeader ? false : true;
      });
    if (this.firstTime) {
      this.sidenavSelectRoute.open();
    }
    if (this.isReconfiguration) {
      this.sidenavSelectRoute.open();
    }
    document
      .getElementsByClassName('mat-tab-header-pagination-before')[0]
      .remove();
    document
      .getElementsByClassName('mat-tab-header-pagination-after')[0]
      .remove();
  }
  async setListOptionsVendedor($event) {
    this.listOptionsVendedor = $event;
  }

  async closeNav({ deleteSelected = false }) {
    this.sidenavSelectRoute.close();
    this.isReconfiguration = false;
    await this.offlineService.loadOfflineHeader();
    const offlineHeader: any = await this.offlineService.getOfflineHeader(
      1,
      this.user.id
    );
    this.offlineActive = offlineHeader ? offlineHeader.status : false;
    setTimeout(() => {
      this.clientAllComponentChild.ngOnInit(true);
      if (deleteSelected) this.filtersMenuInClientsComponent.deleteSelected();
    }, 500);

    this.isOfflineMode = this.offlineActive;
  }

  closeSidenavFiltersMenuInClients() {
    this.sidenavFiltersMenuInClients.close();
  }

  getCantPollToSync(pollId) {
    return this.syncPollsCompleted.filter((x) => x === pollId).length;
  }

  btnSyncControlClass(poll) {
    let classHTML = '';
    classHTML = this.syncPollsCompleted.includes(poll.id)
      ? 'blink btn-sync'
      : '';
    classHTML += this.syncPollsIncomplete.includes(poll.id)
      ? ' btn-warning'
      : ' ';
    return classHTML;
  }

  async loadSyncPolls() {
    this.syncPollsCompleted = [];
    this.syncPolls = await db.syncpolls
      .where({ status: 'sync-completed' })
      .toArray();
    this.syncPolls.forEach((sp) => {
      this.syncPollsCompleted.push(sp.poll_id);
    });
  }

  async loadSyncIncompletePolls() {
    this.syncPollsIncomplete = [];
    const polls = await db.polls
      .where('status')
      .noneOf(['sync-completed', 'unsynchronized'])
      .toArray();
    polls.forEach((p) => {
      this.syncPollsIncomplete.push(p.id);
    });
  }

  getOfflineLocation(val) {
    this.error = '';
    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    navigator.geolocation.getCurrentPosition(
      (success) => {
        if (val === 1) {
          this.lat1 = success.coords.latitude;
          this.long1 = success.coords.longitude;
        }
        if (val === 2) {
          this.lat2 = success.coords.latitude;
          this.long2 = success.coords.longitude;
        }
      },
      (error) => {
        console.warn('ERROR(' + error.code + '): ' + error.message);
        this.error = 'ERROR(' + error.code + '): ' + error.message;
      },
      options
    );
  }

  async fetchPolls() {
    this.spinner.show('loading');
    // Init Offline Mode
    if (this.isOfflineMode) {
      this.polls = [];
      this.polls = await this.offlineService.getPolls();
      this.countPolls = this.polls.length;
      this.spinner.hide('loading');
      return;
    }
    // End Offline Mode
    this.pollsService.getPollsByUser().subscribe(
      (polls) => {
        if (polls === null) {
          this.polls = [];
          this.countPolls = 0;
          this.pollsDraft = [];
          this.countDraft = 0;
          this.spinner.hide('loading');
        } else {
          this.polls = polls.filter((x) => x.status == 'Publicada');
          this.countPolls = this.polls.length;
          this.pollsDraft = polls.filter((x) => x.status == 'Borrador');
          this.countDraft = this.pollsDraft.length;
          this.spinner.hide('loading');
        }
      },
      (err) => {
        this.router.navigateByUrl('/login');
      }
    );
  }

  async checkOffline(poll: any, preview: boolean, user: UserModel) {
    this.getPoll(poll, preview);
    return;
    if (this.syncPollsCompleted.includes(poll.id)) {
      const pollDexie = await db.polls.get(poll.id);
      // console.log('checkOffline pollDexie', pollDexie);
      // console.log(`${pollDexie.last_updated_date} === ${moment().format('DD-MM-YYYY')}`);

      /*  if (
        poll.period_id !== 1
        // && pollDexie.last_updated_date < moment().format('DD-MM-YYYY')
      ) { */
      if (poll.period_id !== 1) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
          data: {
            cancel: false,
            title: 'Encuesta Periodica',
            // message: `Esta es una encuesta periodica y la ultima actualizacion fue ${pollDexie.last_updated_date}, por favor sincronizar primero antes de realizar esta encuesta`,
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
          } else {
          }
        });
        return;
      }
    }
    if (this.syncPollsIncomplete.includes(poll.id)) {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        data: {
          cancel: false,
          title: 'Aviso',
          message:
            'Esta encuesta tiene problemas de sincronización, por favor sincronize para poder entrar',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
        } else {
        }
      });
      return;
    }
    this.getPollDB(poll.id).then((pollBD) => {
      if (pollBD) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
          data: {
            message:
              'Esta encuesta se realizara en modo offline esta de acuerdo?',
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            localStorage.setItem('isOfflineMode', 'yes');
            this.isOfflineMode = true;
            // this.getPoll(pollBD.data, preview);
          } else {
            localStorage.setItem('isOfflineMode', 'no');
          }
        });
      } else {
        localStorage.setItem('isOfflineMode', 'no');
        this.getPoll(poll, preview);
      }
    });
  }

  getPoll(poll: any, preview: boolean) {
    // Sentry.configureScope(function (scope) {
    //   scope.setUser({
    //     usuario: this.user.name,
    //     email: this.user.email,
    //     id: this.user.id,
    //   });

    //   scope.setTags({
    //     poll: poll.name + '(ID:' + poll.id + ')',
    //   });
    // });
    localStorage.removeItem('poll');
    const dataPoll = {
      id: poll.id,
      period_id: poll.period_id,
      name: poll.name,
      description: poll.description,
      sections: this.sortJSON(poll.sections, 'weight', 'asc'),
      messages: poll.messages,
      expire_date: poll.expire_date,
      publish_date: poll.publish_date,
      countquestion: poll.countquestion,
      geolocation: poll.geolocation,
      minutes: poll.minutes,
      infoextra: poll.infoextra,
      infoextrapoll: poll.infoextrapoll,
      infoextracustomer: poll.infoextracustomer,
      showmessageinit: poll.showmessageinit,
      countclient: this.polls.length,
      uen: poll.pollsunes[0]['enterprise_id'],
      filter_by_user: poll.filter_by_user,
      addgoals: poll.addgoals,
      measuring_period: poll.measuring_period,
    };
    this.decodedToken.user.id;
    localStorage.removeItem('filterPollData');
    const res: any = localStorage.getItem('filterPollAllData');

    if (res !== null) {
      const filterPollData = JSON.parse(res).filter((item) => {
        return item.user_id === this.userId && item.poll_id === poll.id;
      });
      if (filterPollData.length > 0) {
        localStorage.setItem('filterPollData', JSON.stringify(filterPollData));
      }
    }

    localStorage.setItem('poll', JSON.stringify(dataPoll));
    localStorage.setItem('preview', JSON.stringify(preview));
    localStorage.setItem('source', 'poll');
    localStorage.setItem('selectPoll', 'poll-list');

    if (preview === true) {
      this.router.navigate(['/poll/client', { id: poll.id }]);
    } else {
      if (poll.addgoals === true) {
        this.router.navigate(['/poll/dashboard-goals'], {
          queryParams: { id: poll.id },
        });
      } else if (poll.period_id === 1) {
        this.router.navigate(['/poll/dashboard'], {
          queryParams: { id: poll.id },
        });
      } else {
        this.router.navigate(['/poll/dashboard-period'], {
          queryParams: { id: poll.id },
        });
      }
    }
  }

  selectedUser(poll: PollModel) {
    this.dialog.open(DialogComponent, {
      data: {
        message: 'Ingresará con el usuario ' + poll.name,
        status: 0,
      },
    });
  }

  sortJSON(data, key, orden) {
    return data.sort(function (a, b) {
      var x = a[key],
        y = b[key];

      if (orden === 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }

      if (orden === 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      }
    });
  }

  async getSectionsPoll(poll) {
    return new Promise<void>(async (resolve, reject) => {
      let pollData = poll;
      for (const item of pollData.sections) {
        const section = await this.sectionsService
          .getSection(item.id)
          .toPromise();
        item.image = section.image;
        item.questions = section.questions;
      }
      resolve(pollData);
    });
  }

  async fetchStatsPoll(poll) {
    return new Promise<void>(async (resolve, reject) => {
      if (poll.period_id === 1) {
        const res: any = await this.statsService
          .getStatsByPoll(poll.id)
          .toPromise();
        resolve(res);
      } else {
        const res: any = await this.statsService
          .getStatsByPollPeriod(poll.id)
          .toPromise();
        resolve(res);
      }
    });
  }

  async fetchCategoryTargetFilter(poll) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const res = await this.categoryTargetService
          .getCategoryTarget(poll.id, [])
          .toPromise();
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  async fetchClientsPoll(poll, status) {
    return new Promise<void>(async (resolve, reject) => {
      const res: any = await this.pollsService
        .getClientByPollByPage(poll.id, false, 1, 0, status, '', [])
        .pipe(debounceTime(2000))
        .toPromise();
      resolve(res);
    });
  }

  async getPollDB(id) {
    const pollDataDB = await db.polls.get(id);
    return pollDataDB;
  }

  async delPollDB(id) {
    const pollDataDB: any = await db.polls.get(id);
    this.spinner.show('loading');
    await db.polls.delete(id);
    await db.stats.delete(id);
    await db.targetpoll.delete(id);
    await db.clients.where('poll_id').equals(id).delete();

    await db.clientsCompleted.where('poll_id').equals(id).delete();
    await db.responses.where('poll_id').equals(id).delete();
    await db.files.where('poll_id').equals(id).delete();
    await db.syncpolls.where('poll_id').equals(id).delete();
    for (var i = 0; i < this.offlinePolls.length; i++) {
      if (this.offlinePolls[i] === id) {
        this.offlinePolls.splice(i, 1);
        this.spinner.hide('loading');
      }
    }
    this.loadSyncPolls();
    this.loadSyncIncompletePolls();

    this.analytics.logEvent(
      `desactivacion_sin_conexion_${pollDataDB.data.name
        .replace(' ', '_')
        .toLowerCase()}`,
      {
        usuario: this.user.email,
        encuesta: pollDataDB.data.name,
      }
    );
  }

  async bgDownload(poll) {
    const registration = await navigator.serviceWorker.ready;
    await registration.sync.register('my-tag-name');
  }

  async checkStatusOfflinePoll(poll) {
    const pollData: any = await db.polls.get(poll.id);
    return pollData.status;
  }

  private readBase64(file): Promise<any> {
    const reader = new FileReader();
    const future = new Promise((resolve, reject) => {
      reader.addEventListener(
        'load',
        function () {
          resolve(reader.result);
        },
        false
      );
      reader.addEventListener(
        'error',
        function (event) {
          reject(event);
        },
        false
      );

      reader.readAsDataURL(file);
    });
    return future;
  }

  uploadFile(fileBase64, name, params) {
    return new Promise<any>(async (resolve, reject) => {
      this.uploadService
        .pushFileBase64ToStorage(fileBase64, name, '')
        .subscribe(
          (event) => {
            if (event.type === HttpEventType.UploadProgress) {
              let percentage = Math.round((event.loaded / event.total) * 100);
              if (percentage == 100) {
              }
            } else if (event instanceof HttpResponse) {
              resolve(event);
            }
          },
          (err) => {
            Swal.fire({
              icon: 'error',
              title: `Error al sincronizar archivo`,
              customClass: {
                container: 'poll-popup-alert-position',
              },
              showConfirmButton: true,
              showCancelButton: false,
              confirmButtonText: 'Reintentar',
            }).then((result) => {
              if (result.isConfirmed) {
                const {
                  idResponse,
                  syncPoll,
                  responses,
                  porResponse,
                  porPoll,
                } = params;
                this.loopSyncResponse(
                  idResponse,
                  syncPoll,
                  responses,
                  porResponse,
                  porPoll
                );
              }
            });
          }
        );
    });
  }

  uploadPdFile(file, name, params) {
    return new Promise<any>(async (resolve, reject) => {
      this.uploadService.pushFilePDFToStorage(file, name).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            let percentage = Math.round((event.loaded / event.total) * 100);
            if (percentage == 100) {
            }
          } else if (event instanceof HttpResponse) {
            resolve(event);
          }
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            title: `Error en el envio de la Imagen la servidor`,
            customClass: {
              container: 'poll-popup-alert-position',
            },
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: 'Reintentar',
          }).then((result) => {
            if (result.isConfirmed) {
              const { idResponse, syncPoll, responses, porResponse, porPoll } =
                params;
              this.loopSyncResponse(
                idResponse,
                syncPoll,
                responses,
                porResponse,
                porPoll
              );
            }
          });
        }
      );
    });
  }

  async synchronizePoll(
    poll,
    {
      pollDownloadedService = false,
      categoriesService = false,
      stastService = false,
      clientsService = false,
      clientsCompletedService = false,
    }
  ) {
    const porSync = 20;
    await db.setPoll({
      id: poll.poll_id,
      status: 'sync-init',
    });
    this.uploadPollClient = 'Sincronizando';
    this.progressUploadResText = '';
    const pollId = parseInt(poll.poll_id);
    let dataPoll = {
      id: pollId,
      period_id: poll.period_id,
    };

    if (!pollDownloadedService) {
      try {
        const pollDataSections = await this.getSectionsPoll(poll);
        await db.setPoll({
          id: poll.poll_id,
          data: pollDataSections,
          status: 'sync-downloaded',
        });
        this.progressUploadResValue = this.progressUploadResValue + porSync;
        this.downloadhelpImage(poll.poll_id);
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: Descargando la Encuesta`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.synchronizePoll(poll, {});
          }
        });
        return;
      }
    }

    if (!categoriesService) {
      try {
        const categories = await this.fetchCategoryTargetFilter(poll);

        await db.setTargetpoll({
          id: poll.poll_id,
          categories,
          user_id: this.decodedToken.user.id,
        });
        await db.setPoll({
          id: poll.poll_id,
          status: 'sync-categories',
        });
        this.progressUploadResValue = this.progressUploadResValue + porSync;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: Categorias de la Encuesta`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.synchronizePoll(poll, { pollDownloadedService: true });
          }
        });
        return;
      }
    }

    if (!stastService) {
      try {
        const stats = await this.fetchStatsPoll(dataPoll);
        await db.setStast({ id: poll.poll_id, data: stats });
        await db.setPoll({
          id: poll.poll_id,
          status: 'sync-stast',
        });
        this.progressUploadResValue = this.progressUploadResValue + porSync;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: estadisticas`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
            });
          }
        });
        return;
      }
    }

    if (!clientsService) {
      try {
        const clients: any = await this.fetchClientsPoll(dataPoll, false);
        await db.clients.where('poll_id').equals(pollId).delete();
        await db.clients.bulkAdd(clients.rows);
        await db.setPoll({
          id: poll.poll_id,
          status: 'sync-clientsPending',
        });
        this.progressUploadResValue = this.progressUploadResValue + porSync;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: clientes`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
              stastService: true,
            });
          }
        });
        return;
      }
    }

    if (!clientsCompletedService) {
      try {
        const clientsCompleted: any = await this.fetchClientsPoll(
          dataPoll,
          true
        );
        await db.clientsCompleted.where('poll_id').equals(pollId).delete();
        if (clientsCompleted.count > 0) {
          const clientsCompletedMaps = clientsCompleted.rows.map((p) => {
            const b = p;
            b.date_finish = p.responses[0].date_finish_date;
            delete b.responses;
            return b;
          });
          // await db.clientsCompleted.bulkAdd(clientsCompleted.rows);
          await db.clientsCompleted.bulkAdd(clientsCompletedMaps);
        }
        await db.setPoll({
          id: poll.poll_id,
          status: 'sync-completed',
        });
        this.progressUploadResValue = this.progressUploadResValue + porSync;
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: `Error sincronzando: clientes completados`,
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
        }).then((result) => {
          if (result.isConfirmed) {
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
              stastService: true,
              clientsService: true,
            });
          }
        });
        return;
      }
    }

    setTimeout(() => {
      this.spinner.hide('synchronizing');
      // setTimeout(() => {
      this.fetchPolls();
      this.loadSyncPolls();
      this.loadSyncIncompletePolls();
      // }, 800);
    }, 800);
  }

  async downloadhelpImage(pollId) {
    let poll: any = await db.polls.get(pollId);
    for (const section of poll.data.sections) {
      for await (const question of section.questions) {
        if (question.helpfile.length > 0) {
          const img = await this.imagesService
            .getImageHelpQuestionBase64(question.helpfile, 'config')
            .toPromise();
          question.helpfile64 = img.url;
        }
      }
    }
    await db.setPoll({
      id: pollId,
      data: poll.data,
    });
  }

  async pollSync(syncPoll, porPoll) {
    this.spinner.show('synchronizing');
    // if (this.syncPollsCompleted.includes(poll.id)) {
    // this.loopSyncPoll(syncPoll, porPoll);
    /* } else {
      poll.poll_id = poll.id;
      this.progressUploadResValue = 100;
      if (this.syncPollsIncomplete.includes(poll.id)) {
        const pollDB = await db.polls.get(poll.id);
        switch (pollDB.status) {
          case 'sync-init':
          case 'syncpolls-delete':
            this.progressUploadResValue = 0;
            this.synchronizePoll(poll, {});
            break;
          case 'sync-downloaded':
            this.progressUploadResValue = 20;
            this.synchronizePoll(poll, { pollDownloadedService: true });
            break;
          case 'sync-categories':
            this.progressUploadResValue = 40;
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
            });
            break;
          case 'sync-stast':
            this.progressUploadResValue = 60;
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
              stastService: true,
            });
            break;
          case 'sync-clientsPending':
            this.progressUploadResValue = 80;
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
              stastService: true,
              clientsService: true,
            });
            break;
          case 'sync-clientsCompleted':
            this.progressUploadResValue = 100;
            this.fetchPolls();
            this.loadSyncPolls();
            this.loadSyncIncompletePolls();
            break;
        }
        return;
      }

      this.progressUploadResValue = 0;
      this.synchronizePoll(poll, {});
    } */
  }

  async isGoodConecction() {
    return new Promise<any>(async (resolve) => {
      let speedMbps;
      let sumSpeed = 0;
      for (let step = 0; step < 5; step++) {
        speedMbps = await this.offlineService.testConection();
        if (Number(speedMbps.duration) >= 20) {
          // Detenemos si alguna consulta supera los 20 segundos
          resolve(false);
          break;
        }
        sumSpeed += speedMbps.duration;
      }
      const promedio = Number(sumSpeed) / 5;
      resolve(promedio > 3 ? false : true);
    });
  }

  async runPollSync($event, ignoreConection = false) {
    setTimeout(() => {
      this.progressUploadPollValue = 0;
      this.progressUploadPollText = 'Status General';
      this.progressUploadResValue = 0;
      this.progressUploadResText = 'Completado';
    }, 100);

    this.spinner.show('synchronizing');

    if (ignoreConection === false) {
      if (!(await this.isGoodConecction())) {
        Swal.fire({
          icon: 'warning',
          customClass: {
            container: 'poll-popup-alert-position',
          },
          title: `Alerta de conexión`,
          html: `Se ha detectado problemas de conectividad, esto podria afectar la sincronización, aun asi desea Continuar?`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Continuar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.runPollSync($event, true);
          } else if (result.isDenied) {
            this.spinner.hide('synchronizing');
          }
        });
        return;
      }
    }

    this.spinner.hide('synchronizing');
    this.dataInitPollClientAll = {
      initPoll: $event && $event.initPoll ? $event.initPoll : false,
      deactiveOffline:
        $event && $event.deactiveOffline ? $event.deactiveOffline : false,
      data: $event && $event.data ? $event.data : [],
    };

    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then(async (offlineHeader) => {
        this.nosleep.enable();
        if (offlineHeader.statusSync === 'good') {
          this.runSynClients();
          return;
        }

        this.syncPolls = await this.offlineService.getSyncPolls();

        if (this.syncPolls.length > 0) {
          this.synchronizationService.nextStopIsRunning({
            status: true,
            step: 'Iniciando sincronizacion.',
          });

          this.progressUploadPollText = 'Sincronizando';
          this.spinner.show('synchronizing');
          this.progressUploadPollValue = 0;
          this.isUpload = true;
          this.controlLoopSyncPoll = 0;

          const porPoll: number = 100 / this.syncPolls.length;

          await this.loopSyncPoll(porPoll);

          const arrayPollByStats = this.syncPolls.map((item) => {
            return item['poll_id'];
          });

          this.pollByStats = arrayPollByStats.filter(
            (value, index) => arrayPollByStats.indexOf(value) === index
          );
        } else {
          this.runSynClients();
        }
      })
      .then(async () => {});
  }

  runSynClients() {
    this.progressUploadPollText = 'Status General';
    this.progressUploadPollValue = 100;
    this.progressUploadResValue = 0;
    this.progressUploadResText = 'Sincronizando Clientes';
    this.spinner.show('synchronizing');
    this.synchronizeClientsPoll();
  }

  async loopSyncPoll(porPoll) {
    const syncPoll = this.syncPolls[this.controlLoopSyncPoll];
    if (
      syncPoll.status === 'unsynchronized' ||
      syncPoll.status === 'unsynchronized-edit'
    ) {
      this.uploadPollClient = syncPoll.client.descr_cliente;
      const poll = await this.offlineService.getPoll(syncPoll.poll_id);

      this.uploadPoll = poll.name;
      this.progressUploadPollValue = parseInt(
        this.progressUploadPollValue + porPoll
      );
      this.syncResponse(syncPoll, porPoll);
    } else {
      this.resolveCaseSyncError(syncPoll, porPoll);
    }
  }

  async resolveCaseSyncError(syncPoll, porPoll) {
    switch (syncPoll.status) {
      case 'sync-responses-completed':
        this.progressUploadResText = 'Sincronizando';
        this.progressUploadResValue = 100;
        this.closePollSync(
          syncPoll.params.client,
          syncPoll.params.idResponse,
          syncPoll.params.pollSync,
          syncPoll.params.porPoll
        );
        break;
      case 'sync-close-response':
        this.controlLoopSyncPoll = syncPoll.params.controlLoopSyncPoll;
        this.delSyncResponses(
          syncPoll.params.pollSync,
          syncPoll.params.client,
          syncPoll.params.idSyncResponse,
          syncPoll.params.porPoll
        );
        break;
      case 'sync-poll-completed':
      case 'sync-clients-completed':
      case 'sync-completed':
        this.controlLoopSyncPoll = syncPoll.params.controlLoopSyncPoll;

        if (this.controlLoopSyncPoll < syncPoll.params.syncPolls.length) {
          this.progressUploadResText = 'Sincronizando';
          this.progressUploadResValue = 0;
          this.loopSyncPoll(syncPoll.params.porPoll);
        } else {
          this.progressUploadResValue = 0;
          this.progressUploadResText = 'Sincronizando Clientes';

          await this.offlineService.setOfflineHeader({
            id: 1,
            user_id: this.user.id,
            statusSync: 'sync-poll-completed',
          });
          this.synchronizeClientsPoll();
        }
        break;
      case 'edit-offline':
      case 'iniciada':
        break;
    }
  }

  async syncResponse(syncPoll, porPoll) {
    const sync_poll_id = `${syncPoll.poll_id}-${syncPoll.client.id}-${this.user.id}`;
    const responses = await db.responses.where({ sync_poll_id }).toArray();
    this.receiveResponse(responses, syncPoll, porPoll);
  }

  receiveResponse(responses, syncPoll, porPoll) {
    syncPoll.status === 'unsynchronized'
      ? this.createResponse(responses, syncPoll, porPoll)
      : this.createResponseEdit(responses, syncPoll, porPoll);
  }

  createResponseEdit(responses, syncPoll, porPoll) {
    const user = jwt_decode(localStorage.getItem('token')).user;
    const data = {
      customer_id: syncPoll.client.cliente_id,
      poll_id: syncPoll.poll_id,
      user_id: user.id,
      lat: syncPoll.coords_init.lat,
      long: syncPoll.coords_init.long,
      method: 'cliente_id_edit_offline',
    };

    this.pollsService.sendPollEdit(data).subscribe(
      (response) => {
        localStorage.setItem('idResponse', response.id);
        const porResponse: number = 100 / responses.length;
        this.progressUploadResValue = 0;
        this.progressUploadResText = 'Sincronizando';
        this.controlLoopSyncRes = 0;
        this.loopSyncResponse(
          response.id,
          syncPoll,
          responses,
          porResponse,
          porPoll
        );
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          customClass: {
            container: 'poll-popup-alert-position',
          },
          title: `Error de sincronización al crear la respuesta`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.createResponseEdit(syncPoll, responses, porPoll);
          } else if (result.isDenied) {
            this.spinner.hide('synchronizing');
          }
        });
      }
    );
  }

  createResponse(responses, syncPoll, porPoll) {
    const user = jwt_decode(localStorage.getItem('token')).user;

    const data = {
      customer_id: syncPoll.client.id,
      poll_id: syncPoll.poll_id,
      user_id: user.id,
      lat: syncPoll.coords_init.lat,
      long: syncPoll.coords_init.long,
      cliente_id: syncPoll.client.cliente_id,
      method: 'offline',
      empresa_id: syncPoll.client.empresa_id,
    };

    this.responseService.sendResponse(data).subscribe(
      async (response) => {
        localStorage.setItem('idResponse', response.id);
        const porResponse: number = 100 / responses.length;
        this.progressUploadResValue = 0;
        this.progressUploadResText = 'Sincronizando';
        this.controlLoopSyncRes = 0;

        this.loopSyncResponse(
          response.id,
          syncPoll,
          responses,
          porResponse,
          porPoll
        );
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          customClass: {
            container: 'poll-popup-alert-position',
          },
          title: `Error de sincronización al crear la respuesta`,
          showDenyButton: true,
          showCancelButton: false,
          confirmButtonText: 'Reintentar',
          denyButtonText: `Cancelar`,
        }).then((result) => {
          if (result.isConfirmed) {
            this.createResponse(syncPoll, responses, porPoll);
          } else if (result.isDenied) {
            this.spinner.hide('synchronizing');
          }
        });
      }
    );
  }

  async loopSyncResponse(
    idResponse,
    syncPoll,
    responses,
    porResponse,
    porPoll
  ) {
    let response = responses[this.controlLoopSyncRes];

    if (!response?.data) {
      response = {
        data: {
          idResponse,
          response_id: idResponse,
        },
      };
    } else {
      response.data.response_id = idResponse;
    }
    const { data, type_question, type_save, question_title, type_desc } =
      response;
    const promises = [];

    if (type_question === 'cooler') {
      Promise.all(promises).then(async (values) => {
        await this.save(
          data,
          type_question,
          responses,
          idResponse,
          syncPoll,
          porResponse,
          type_save,
          porPoll,
          question_title,
          type_desc
        );
      });
    } else {
      if (type_save === 'saveFileQuestion') {
        const params = {
          idResponse,
          syncPoll,
          responses,
          porResponse,
          porPoll,
        };
        for (const element of data.value) {
          const token = element.value.split('/')[0].toLowerCase();
          const newId = `${response.id}-${token}`;
          const file = await db.files.get(newId);
          if (file) {
            const { imagenBase64 = null, fileName = null } = file.data;
            const extension = fileName.split('.').pop().toLowerCase();
            if (extension === 'pdf') {
              const newbase64 = await this.readBase64(imagenBase64);
              const fileUploaded = await this.uploadPdFile(
                newbase64,
                fileName,
                params
              );
              const { token, name } = fileUploaded.body;
              element.value = `${token}/${name}`;
            } else {
              const fileUploaded = await this.uploadFile(
                imagenBase64,
                fileName,
                params
              );
              const { token, name } = fileUploaded.body;
              element.value = `${token}/${name}`;
            }
          } else {
            delete element.value;
            delete element.idResponse;
          }
        }
      }

      if (type_question) {
        await this.save(
          data,
          type_question,
          responses,
          idResponse,
          syncPoll,
          porResponse,
          type_save,
          porPoll,
          question_title,
          type_desc
        );
      } else {
        this.progressUploadResText = 'Sincronizando';
        this.progressUploadResValue = 100;
        setTimeout(() => {
          this.closePollSync(syncPoll.client, idResponse, syncPoll, porPoll);
        }, 500);
      }
    }
  }

  async save(
    data,
    type_question,
    responses,
    idResponse,
    poll,
    porResponse,
    type_save,
    porPoll,
    question_title,
    type_desc
  ) {
    await this.responseService
      .sendResponseQuestion(data, type_question)
      .subscribe(
        async (res) => {
          this.synchronizationService.nextStopIsRunning({
            status: true,
            step: `Guardando respuestas de la encuesta ${poll.poll_id}`,
          });

          this.progressUploadResValue = parseInt(
            this.progressUploadResValue + porResponse
          );
          this.controlLoopSyncRes++;
          if (this.controlLoopSyncRes < responses.length) {
            this.loopSyncResponse(
              idResponse,
              poll,
              responses,
              porResponse,
              porPoll
            );
          } else {
            this.progressUploadResText = 'Sincronizando';
            this.progressUploadResValue = 100;
            setTimeout(() => {
              this.closePollSync(poll.client, idResponse, poll, porPoll);
            }, 500);
          }
        },
        (err) => {
          Swal.fire({
            icon: 'error',
            title: `Ocurrio un error al sincronizar`,
            html: `<b>Pregunta</b> <br> ${question_title} <br> <br> <b>Tipo</b> <br>  ${type_desc}`,
            customClass: {
              container: 'poll-popup-alert-position',
            },
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Reintentar',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.save(
                data,
                type_question,
                responses,
                idResponse,
                poll,
                porResponse,
                type_save,
                porPoll,
                question_title,
                type_desc
              );
            } else if (result.isDenied) {
              this.spinner.hide('synchronizing');
            }
          });
        }
      );
  }

  async closePollSync(client, idResponse, pollSync, porPoll) {
    let dataSyncPoll: any = {
      id: `${pollSync.poll_id}-${client.id}-${this.user.id}`,
      status: 'sync-responses-completed',
      params: { client, idResponse, pollSync, porPoll },
    };
    await db.setSyncPoll(dataSyncPoll);
    this.progressUploadResText = 'Cerrando';
    this.progressUploadResValue = 0;
    this.responseService
      .closeResponse({
        customer_id: client.id,
        poll_id: pollSync.poll_id,
        cliente_id: client.cliente_id,
        empresa_id: client.empresa_id,
        response_id: idResponse,
        lat: pollSync.coords_finish ? pollSync.coords_finish.lat : '',
        long: pollSync.coords_finish ? pollSync.coords_finish.long : '',
        date_init_save: pollSync.date_init_save,
        date_finish_save: pollSync.date_finish_save,
      })
      .subscribe(
        async (data) => {
          const idSyncResponse = `${pollSync.poll_id}-${client.id}-${this.user.id}`;
          let dataSyncPoll: any = {
            id: idSyncResponse,
            status: 'sync-close-response',
            params: {
              pollSync,
              client,
              user: this.user,
              idSyncResponse,
              controlLoopSyncPoll: this.controlLoopSyncPoll,
              syncPolls: this.syncPolls,
              porPoll,
            },
          };
          await db.setSyncPoll(dataSyncPoll);
          this.delSyncResponses(pollSync, client, idSyncResponse, porPoll);
        },
        async (err) => {
          if (err.status === 409) {
            const idSyncResponse = `${pollSync.poll_id}-${client.id}-${this.user.id}`;
            let dataSyncPoll: any = {
              id: idSyncResponse,
              status: 'sync-close-response',
              params: {
                pollSync,
                client,
                user: this.user,
                idSyncResponse,
                controlLoopSyncPoll: this.controlLoopSyncPoll,
                syncPolls: this.syncPolls,
                porPoll,
              },
            };
            await db.setSyncPoll(dataSyncPoll);
            this.delSyncResponses(pollSync, client, idSyncResponse, porPoll);
            return;
          }
          Swal.fire({
            icon: 'error',
            title: `Error de sincronización al cerrar la encuesta`,
            customClass: {
              container: 'poll-popup-alert-position',
            },
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Reintentar',
            denyButtonText: `Cancelar`,
          }).then((result) => {
            if (result.isConfirmed) {
              this.closePollSync(client, idResponse, pollSync, porPoll);
            } else if (result.isDenied) {
              this.spinner.hide('synchronizing');
            }
          });
        }
      );
  }

  delSyncResponses(pollSync, client, idSyncResponse, porPoll) {
    this.controlLoopSyncPoll++;
    this.progressUploadResValue = 90;
    setTimeout(async () => {
      await this.offlineService.delResponses(pollSync, client, this.user);
      let dataSyncPoll = {
        id: idSyncResponse,
        status: 'sync-completed',
        params: {
          controlLoopSyncPoll: this.controlLoopSyncPoll,
          syncPolls: this.syncPolls,
          porPoll,
        },
      };
      await db.setSyncPoll(dataSyncPoll);
      if (this.controlLoopSyncPoll < this.syncPolls.length) {
        this.progressUploadResText = 'Sincronizando';
        this.progressUploadResValue = 0;
        this.loopSyncPoll(porPoll);
      } else {
        this.progressUploadResValue = 0;
        this.progressUploadResText = 'Sincronizando Clientes';

        await this.offlineService.setOfflineHeader({
          id: 1,
          user_id: this.user.id,
          statusSync: 'sync-poll-completed',
        });
        this.synchronizeClientsPoll();
      }
    }, 500);
  }

  async synchronizeClientsPoll() {
    this.uploadPollClient = '';
    this.uploadPoll = '';
    this.offlineService
      .getOfflineHeader(1, this.user.id)
      .then((offlineHeader) => {
        this.synchronizationService.nextStopIsRunning({
          status: true,
          step: `Descargando clientes.`,
        });

        this.clientsService
          .getClientsAndPollsBySellers(
            offlineHeader.mode === 'sellers' ? offlineHeader.sellers : []
          )
          .subscribe(
            async (data) => {
              await this.offlineService.delSyncPolls();
              await this.offlineService.delSResponsesPolls();

              this.progressUploadResValue = 20;
              this.progressUploadResText = 'Guardando Clientes';

              await this.offlineService.setClients(data.clients);
              await this.offlineService.setOfflineHeader({
                id: 1,
                user_id: this.user.id,
                statusSync: 'sync-clients-completed',
              });

              this.synchronizationService.nextStopIsRunning({
                status: true,
                step: `Descargando encuestas.`,
              });

              this.progressUploadResValue = 25;
              this.progressUploadResText = 'Descargando Polls';
              let polls: any = await this.offlineService.getSectionsPoll(
                data.polls
              );
              await this.offlineService.setPolls(polls);

              this.synchronizationService.nextStopIsRunning({
                status: true,
                step: `Descargando categorias.`,
              });

              this.progressUploadResValue = 25;
              this.progressUploadResText = 'Descargando Categorias';
              await this.offlineService.downloadCategories(
                offlineHeader.mode === 'sellers' ? offlineHeader.sellers : []
              );

              await this.offlineService.setOfflineHeader({
                id: 1,
                user_id: this.user.id,
                date: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
                number_clients: data.clients.length,
                number_polls: polls.length,
                statusSync: 'good',
              });

              this.progressUploadResValue = 100;
              this.progressUploadResText = 'Completado';

              this.nosleep.disable();
              this.spinner.hide('synchronizing');
              this.synchronizationService.nextUploadIsRunning(false);
              this.clientAllComponentChild.ngOnInit(true);

              this.synchronizationService.nextStopIsRunning({
                status: true,
                step: `Finalizando descargas.`,
              });

              setTimeout(() => {
                this.synchronizationService.nextStopIsRunning({
                  status: false,
                  step: ``,
                });
              }, 1000);

              for await (const id of this.pollByStats) {
                this.statsService
                  .getStatsByPollPeriodGoals(id, this.listOptionsVendedor)
                  .subscribe(async (data) => {
                    data['user_id'] = this.user.id;
                    data['id'] = `${id}-${this.user.id}`;
                    await this.offlineService.setPollStats(data);
                  });
              }
              // End Syncronization
              if (this.dataInitPollClientAll.initPoll) {
                this.clientAllComponentChild.initPoll(
                  this.dataInitPollClientAll.data[0],
                  this.dataInitPollClientAll.data[1]
                );
              }

              if (this.dataInitPollClientAll.deactiveOffline) {
                this.clientAllComponentChild.deactiveOffline();
              }
            },
            (err) => {
              if (err.name === 'TimeoutError') {
                // Manejar el error de tiempo de espera aquí
                Swal.fire({
                  icon: 'error',
                  title: `Error: tiempo de espera agotado`,
                  html: `Se agoto tiempo de espera para sincronizacion de clientes, posiblemente tenga baja conexion a internet`,
                  customClass: {
                    container: 'poll-popup-alert-position',
                  },
                  showConfirmButton: false,
                  showCancelButton: true,
                  confirmButtonText: 'Reintentar',
                }).then((result) => {
                  this.spinner.hide('synchronizing');
                });
              }
            }
          );
      });
  }

  async pollSyncResponses(poll) {
    this.spinner.show('synchronizing');
    if (this.syncPollsCompleted.includes(poll.id)) {
      this.progressUploadPollValue = 0;
      this.isUpload = true;
      const pollsToSync = this.syncPolls.filter((p) => p.poll_id === poll.id);
      const porPoll: number = 100 / pollsToSync.length;
      this.controlLoopSyncPoll = 0;
      this.loopSyncPoll(pollsToSync);
    } else {
      poll.poll_id = poll.id;
      this.progressUploadResValue = 100;
      if (this.syncPollsIncomplete.includes(poll.id)) {
        const pollDB = await db.polls.get(poll.id);
        switch (pollDB.status) {
          case 'sync-init':
          case 'syncpolls-delete':
            this.progressUploadResValue = 0;
            this.synchronizePoll(poll, {});
            break;
          case 'sync-downloaded':
            this.progressUploadResValue = 20;
            this.synchronizePoll(poll, { pollDownloadedService: true });
            break;
          case 'sync-categories':
            this.progressUploadResValue = 40;
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
            });
            break;
          case 'sync-stast':
            this.progressUploadResValue = 60;
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
              stastService: true,
            });
            break;
          case 'sync-clientsPending':
            this.progressUploadResValue = 80;
            this.synchronizePoll(poll, {
              pollDownloadedService: true,
              categoriesService: true,
              stastService: true,
              clientsService: true,
            });
            break;
          case 'sync-clientsCompleted':
            this.progressUploadResValue = 100;
            this.fetchPolls();
            this.loadSyncPolls();
            this.loadSyncIncompletePolls();
            break;
        }
        return;
      }

      this.progressUploadResValue = 0;
      this.synchronizePoll(poll, {});
    }
  }

  async pollSynchronizationControl(poll) {
    this.getPollDB(poll.id).then(async (pollBD) => {
      if (!pollBD) {
        this.spinner.show('synchronizing');

        this.analytics.logEvent(
          `activacion_sin_conexion_${poll.name
            .replace(' ', '_')
            .toLowerCase()}`,
          {
            usuario: this.user.email,
            encuesta: poll.name,
          }
        );

        poll.poll_id = poll.id;
        this.progressUploadResValue = 100;
        this.progressUploadResValue = 0;
        this.synchronizePoll(poll, {});
      } else {
        if (this.syncPollsIncomplete.includes(poll.id)) {
          const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
              cancel: false,
              title: 'Aviso',
              message:
                'Tiene encuestas con problemas de sincronización, por favor sincronce correctamente y luego intente nuevamente',
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
            } else {
            }
          });
          return;
        }
        if (this.syncPollsCompleted.includes(poll.id)) {
          const dialogRef = this.dialog.open(ConfirmComponent, {
            data: {
              title: 'Aviso',
              message:
                'Tiene encuestas por sincronizar, si desactiva el modo offline se perderan los datos cargados',
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.confirm(poll.id);
            } else {
            }
          });
          return;
        }
        this.confirm(poll.id);
      }
    });
  }

  confirm(pollID): void {
    const dialogRef = this.dialog.open(ConfirmComponent, {
      data: {
        message: 'Esta seguro(a) de quitar esta encuesta del modo offline?',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delPollDB(pollID);
      } else {
        this.spinner.hide('loading');
      }
    });
  }

  async resetDB() {
    if (this.controlReset === 10) {
      await db.resetDatabase();
      this.controlReset = 0;
      return;
    }
    this.controlReset++;
  }

  refreshcountClients($event = undefined) {
    this.countClients = $event;
  }
  filterClients(enterprise: EnterpriseModel) {
    this.clientAllComponentChild.resetClients();
    this.clientAllComponentChild.fetchClientsFilter(enterprise);
  }
  public throwTestError2(): void {
    throw new Error('Sentry Test aaaa ' + new Date());
  }
}
