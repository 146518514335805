import { HostListener, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, from, of, timer } from 'rxjs';
import {
  catchError,
  concatMap,
  filter,
  map,
  switchMap,
  tap,
} from 'rxjs/operators';
import { auth } from 'src/environments/environment';
import NoSleep from 'nosleep.js';
import { OfflineService } from './offline.service';
import { ClientsService } from './clients.service';
import { StatsService } from './stats.service';
import { formatDate } from '@angular/common';
import * as jwt_decode from 'jwt-decode';
import { UserModel } from '../models/user.model';
import { UserService } from './user.service';
import { EnterpriseModel } from '../models/enterprise';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  private nosleep = new NoSleep();
  private API_URL: string = auth.urlConfig;
  user: UserModel;
  lastVerificationDateTime: string;
  private enterprises: EnterpriseModel[] = [];
  private navigationExtras: NavigationExtras = {
    state: {
      isReconfiguration: true,
    },
  };

  constructor(
    private http: HttpClient,
    private clientsService: ClientsService,
    private offlineService: OfflineService,
    private statsService: StatsService,
    private userService: UserService,
    private router: Router
  ) {
    this.user = jwt_decode(localStorage.getItem('token')).user;
  }

  @HostListener('window:online', ['$event'])
  handleOnlineEvent() {
    this.initVerification().subscribe();
    this.enterprises = [];
  }
  initVerification(): Observable<boolean> {
    return timer(0, 3600000).pipe(
      concatMap(() => {
        return from(
          this.offlineService
            .getOfflineHeader(1, this.user.id)
            .then((offlineHeader) => {
              this.lastVerificationDateTime =
                offlineHeader?.lastVerificationDateTime;
              const expiresInDuration = parseInt(
                localStorage.getItem('dateTimeDiffWithDB') || '0',
                10
              );
              return (
                !this.lastVerificationDateTime ||
                new Date(this.lastVerificationDateTime).getTime() + 3600000 <
                  Date.now() + expiresInDuration
              );
            })
        );
      }),
      switchMap((hasToVerify) => {
        if (hasToVerify) {
          return this.verifyChanges();
        } else {
          return new Observable<boolean>((subscriber) => {
            subscriber.next(false);
            subscriber.complete();
          });
        }
      })
    );
  }

  private verifyChanges(): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('sellers', '[]');
    params = params.set(
      'lastVerificationDateTime',
      this.lastVerificationDateTime
    );
    return this.http.get<any>(`${this.API_URL}/checks/all`, { params }).pipe(
      tap(async (response) => {
        const keysWithTrueValue = Object.keys(response).filter(
          (key) => response[key] === true
        );
        if (keysWithTrueValue.length) {
          if (
            keysWithTrueValue.includes('checkSaleStructure') &&
            new Date(response.checkSaleStructureLastUpdate).getTime() >
              new Date(this.lastVerificationDateTime).getTime()
          ) {
            // 1ro traer el salesStructure actualizado en indexedDB y pisar el anterior en localStorage
            this.updateUserSalesData();

            // 2do redirigir a poll para obligar al usuario a que sincronice con la nueva saleStructure
            this.router.navigate(['/poll'], this.navigationExtras);
          } else if (
            keysWithTrueValue.includes('checkClients') &&
            new Date(response.checkClientsLastUpdate).getTime() >
              new Date(this.lastVerificationDateTime).getTime()
          ) {
            this.syncChanges();
          }
        }
      }),
      filter((response) => response.changed)
    );
  }

  updateUserSalesData() {
    this.userService.getInfoSalesData('unit').subscribe((data) => {
      let i = 0;
      localStorage.setItem('sales', JSON.stringify(data));

      data.forEach((usersale) => {
        i++;

        if (
          this.enterprises.findIndex(
            (usersaleItem) => usersaleItem.id == usersale.enterprise.id
          ) == -1
        ) {
          this.enterprises.push(usersale.enterprise);
        }

        if (i >= data.length) {
          localStorage.setItem('enterprises', JSON.stringify(this.enterprises));
        }
      });
    });
  }

  async syncChanges() {
    this.clientsService
      .getClientsAndPollsBySellers([])
      .subscribe(async (data) => {
        const clientsLimit = data.clients;
        const enterprises = JSON.parse(
          localStorage.getItem('enterprises')
        )?.map((e) => e.id);
        const customerSku = await this.clientsService
          .getAllSkus(enterprises)
          .toPromise();
        await this.offlineService.setCustomersSku(customerSku['customersSku']);

        await this.offlineService.setClients(clientsLimit);

        let polls: any = await this.offlineService.getSectionsPoll(data.polls);

        await this.offlineService.setPolls(polls);

        await this.offlineService.downloadCategories([]);

        await this.offlineService.clearPollStats();

        const clientPolls = data.polls;

        from(clientPolls)
          .pipe(
            concatMap((poll: any) =>
              this.statsService.getStatsByPollPeriodGoals(poll.id, []).pipe(
                map((data) => ({
                  ...data,
                  user_id: this.user.id,
                  id: `${poll.id}-${this.user.id}`,
                })),
                catchError((error) => {
                  return of(null);
                })
              )
            )
          )
          .subscribe(async (data) => {
            if (data) {
              await this.offlineService.setPollStats(data);
            }
          });

        await this.offlineService.setOfflineHeader({
          id: 1,
          user_id: this.user.id,
          date: formatDate(new Date(), 'yyyy/MM/dd', 'en'),
          number_clients: data.clients.length,
          number_polls: polls.length,
          status: true,
          mode: 'syncAll',
          from: 'clients',
          sellers: [],
          dataFilters: {
            enterpriseId: null,
            filters: [],
            isFilter: false,
            keepFilter: false,
          },
          dataFiltersPoll: [],
          lastVerificationDateTime: new Date(
            Date.now() +
              parseInt(localStorage.getItem('expiresInDuration') || '0', 10)
          ).toISOString(),
        });
      });
  }

  async runBackgroundSync(option = 'backsynapi') {
    await this.offlineService.setBackSyncHeader({
      id: 1,
      user: this.user,
      token: localStorage.getItem('token'),
      status: 'running',
      api_url: this.API_URL,
    });
    if (option === 'webworker') {
      if (typeof Worker !== 'undefined') {
        const worker = new Worker(
          new URL('src/app/web-workers/background-sync.worker', import.meta.url)
        );
        worker.onmessage = ({ data }) => {};
        worker.postMessage({ isAllPolls: true });
      } else {
        console.log('Web workers are not supported in this environment.');
      }
    } else {
      await this.offlineService.runBackgroundSync();
    }
  }
}
