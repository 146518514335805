import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class EnterpriseGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const enterprises = JSON.parse(localStorage.getItem('enterprises') || '[]');
    const idsToCheck = [99, 98, 92];
    const hasAccess = !idsToCheck.every((id) =>
      enterprises.some((enterprise: any) => enterprise.id === id)
    );

    if (!hasAccess) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
