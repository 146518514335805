<div class="content-select-by-selle">
  <app-shared-header
    *ngIf="firstTime && !reconfiguration"
    [showTitle]="true"
    title="Bienvenido"
    [showBadge]="false"
  ></app-shared-header
  ><app-shared-header
    *ngIf="reconfiguration"
    [showTitle]="true"
    title="Actualización"
    [showBadge]="false"
  ></app-shared-header>
  <h5 *ngIf="firstTime && !reconfiguration">
    Antes de comenzar, es necesario completar la configuración inicial de la
    aplicación.
  </h5>
  <h5 *ngIf="reconfiguration">
    Debido a que se cambió la definición de su usuario, es necesario volver a
    configurar la aplición.
  </h5>
  <div fxLayout="row">
    <div fxFlex="17" fxLayoutAlign="start center">
      <button
        mat-mini-fab
        color="primary"
        class="example-tab-icon"
        class="mat-elevation-z"
        (click)="closeNav({})"
        *ngIf="firstTime"
      >
        <mat-icon class="icon_return outline noshadow">arrow_back</mat-icon>
      </button>
    </div>
    <mat-form-field appearance="standard" class="syncOptions">
      <mat-label>Elegir una configuración</mat-label>
      <mat-select
        [(value)]="syncOptionSelected"
        (selectionChange)="selectAllVendedor($event)"
      >
        <mat-option *ngFor="let option of syncOptions" [value]="option.value">
          {{ option.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <span *ngIf="syncOptionSelected" style="padding-top: 3px"
    >Seleccione uno o mas</span
  >
  <div fxLayout="column" fxLayoutAlign="start center" style="height: 90%">
    <ng-container>
      <h4 *ngIf="syncOptionSelected" matLine class="text-filter-category">
        <span>Vendedores</span>
      </h4>
      <div *ngIf="syncOptionSelected" style="height: 100%; width: 95%">
        <mat-form-field class="full-medium-width">
          <mat-label>
            <mat-icon class="icon-label">search</mat-icon>
            &nbsp;&nbsp;Buscar vendedor
          </mat-label>
          <input
            type="text"
            #text_pend
            placeholder="Busca por nombre, códigos de cliente, dirección o vendedor"
            matInput
            [(ngModel)]="searchPend"
            (keyup)="searchOnFilterSellers($event.target.value)"
            autocomplete="off"
          />
        </mat-form-field>
        <div class="content-list">
          <mat-selection-list
            class="content-select-list"
            color="primary"
            multiple="true"
            [(ngModel)]="selectedOptionsVendedor"
            (selectionChange)="onListSelectionChange($event)"
            [compareWith]="compareFunction"
            #VendedorTarget
          >
            <ng-template ngFor let-seller [ngForOf]="allVendedor" let-i="index">
              <mat-list-option
                class="content-select-route"
                [class]="i === allVendedor.length - 1 ? 'last' : ''"
                [value]="seller"
                #optionsVendedores
                [checkboxPosition]="'before'"
                [id]="seller.name"
              >
                <span>{{ seller.code }}-{{ seller.name }}</span>
              </mat-list-option>
            </ng-template>
          </mat-selection-list>
        </div>
      </div>
      <div class="content-button-aplicar">
        <button
          mat-raised-button
          class="btn-next fixed-button menu-button center"
          (click)="!syncOptionSelected ? syncAll() : applySellersSelected()"
          [disabled]="syncOptionSelected === undefined"
        >
          <span>Sincronizar</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
