import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatTabsModule } from '@angular/material/tabs';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {
  MatRippleModule,
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { ReactiveFormsModule } from '@angular/forms';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatBadgeModule } from '@angular/material/badge';
// import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { FilePickerModule } from 'ngx-awesome-uploader';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';

import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { GoogleMapsModule } from '@angular/google-maps';
// import { WebcamModule } from 'ngx-webcam';
import { WebcamModule } from './modules/webcam/webcam.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/components/login/login.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { BreadCrumbComponent } from './questions/components/bread-crumb/bread-crumb.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthInterceptor } from './auth/auth-interceptor';

// import {
//   SocialLoginModule,
//   SocialAuthServiceConfig,
//   GoogleLoginProvider,
// } from 'angularx-social-login';
import { auth, environment, sw } from '../environments/environment';
import { PollsComponent } from './polls/components/polls/polls.component';
import { ClientsComponent } from './polls/components/clients/clients.component';
// import { ClientPollComponent } from './polls/components/client-poll/client-poll.component';
import { HeaderUserComponent } from './shared/components/header-user/header-user.component';
import { QuestionComponent } from './polls/components/questions/question/question.component';
import { SelectionComponent } from './polls/components/questions/selection/selection.component';
import { InfoextraComponent } from './polls/components/infoextra/infoextra.component';
import { MessageInitComponent } from './polls/components/message-init/message-init.component';
import { MessageFinishComponent } from './polls/components/message-finish/message-finish.component';
import { B2bloginComponent } from './polls/components/b2blogin/b2blogin.component';
import { DialogImageHelpComponent } from './polls/components/dialog-image-help/dialog-image-help.component';
import { NoSanitizePipe } from './utils/no-sanitize.pipe';
import { MultipleComponent } from './polls/components/questions/multiple/multiple.component';
import { TextComponent } from './polls/components/questions/text/text.component';
import { LoadfileComponent } from './polls/components/questions/loadfile/loadfile.component';
import { ScaleComponent } from './polls/components/questions/scale/scale.component';
import { NumberComponent } from './polls/components/questions/number/number.component';
// import { RankingComponent } from './polls/components/questions/ranking/ranking.component';
import { DashboardPollComponent } from './polls/components/dashboard-poll/dashboard-poll.component';
import { DashboardPollGoalsComponent } from './polls/components/dashboard-poll-goals/dashboard-poll-goals.component';
import { RankingmvComponent } from './polls/components/questions/rankingmv/rankingmv.component';
// import { Loadfile2Component } from './polls/components/questions/loadfile2/loadfile2.component';
import { FileDropModule } from './polls/components/questions/loadfile2/file-drop';
import { FilePreviewContainerComponent } from './polls/components/questions/loadfile2/file-preview-container/file-preview-container.component';
import { FilePreviewItemComponent } from './polls/components/questions/loadfile2/file-preview-container/file-preview-item/file-preview-item.component';
import { RefreshIconComponent } from './polls/components/questions/loadfile2/file-preview-container/file-preview-item/refresh-icon/refresh-icon.component';
import { CloseIconComponent } from './polls/components/questions/loadfile2/icons/close-icon/close-icon.component';
import { PreviewLightboxComponent } from './polls/components/questions/loadfile2/file-preview-container/preview-lightbox/preview-lightbox.component';
import { FilePickerService } from './polls/components/questions/loadfile2/file-picker.service';
import { DateComponent } from './polls/components/questions/date/date.component';
import { DatetimeComponent } from './polls/components/questions/datetime/datetime.component';
import { PricedropdownComponent } from './polls/components/questions/pricedropdown/pricedropdown.component';
import { DashboardPeriodPollComponent } from './polls/components/dashboard-period-poll/dashboard-period-poll.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';

// import { initializeApp, provideFirebaseApp, getApp } from '@angular/fire/app';
// import { provideAuth, getAuth } from '@angular/fire/auth';
// import { provideFirestore, getFirestore } from '@angular/fire/firestore';

import { SyncComponent } from './sync/sync.component';
import { InitComponent } from './init/init.component';
import { DialogErrorComponent } from './shared/components/dialog-error/dialog-error.component';
import { CoolerComponent } from './polls/components/questions/cooler/cooler.component';
import { CoolerLoadfileComponent } from './polls/components/questions/cooler/components/cooler-loadfile/cooler-loadfile.component';
import { FiltersMenuComponent } from './polls/components/filters-menu/filters-menu.component';
// import {
//   getAnalytics,
//   provideAnalytics,
//   ScreenTrackingService,
//   UserTrackingService,
// } from '@angular/fire/analytics';
import {
  AngularFireAnalyticsModule,
  APP_NAME,
  APP_VERSION,
  DEBUG_MODE as ANALYTICS_DEBUG_MODE,
  ScreenTrackingService,
  UserTrackingService,
  COLLECTION_ENABLED,
} from '@angular/fire/compat/analytics';
import { AngularFireModule } from '@angular/fire/compat';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
  AngularFireMessagingModule,
  SERVICE_WORKER,
  VAPID_KEY,
} from '@angular/fire/compat/messaging';
import { MultipleFilesComponent } from './polls/components/questions/multiple-files/multiple-files.component';
import { ModalViewPreviewComponent } from './shared/components/modal-view-preview/modal-view-preview.component';
import { ClientAllComponent } from './polls/components/client-all/client-all.component';
import { MapsComponent } from './shared/components/maps/maps.component';
import { SelectRouteComponent } from './polls/components/client-all/select-route/select-route.component';
import { FiltersMenuInClientsComponent } from './polls/components/filters-menu-in-clients/filters-menu-in-clients.component';
import { AutloginComponent } from './autlogin/autlogin.component';
import { FilesWebcamComponent } from './polls/components/questions/files-webcam/files-webcam.component';
import { ModalsComponent } from './polls/components/questions/files-webcam/modals/modals.component';
import * as Sentry from '@sentry/angular-ivy';
import { Router } from '@angular/router';
import { IndependentMapsComponent } from './shared/components/independent-maps/independent-maps.component';
import { HeaderV2Component, NavbarComponent } from './shared/components/v2';
import { SynchronizationComponent } from './synchronization/components/sync/synchronization.component';
import { HomeComponent } from './home/components/home/home.component';
import { PublishedPollsComponent } from './published/components/published-polls/published-polls.component';
import { TasksComponent } from './tasks/components/tasks/tasks.component';
import { TasksAssignedComponent } from './tasks/components/tasks-assigned/tasks-assigned.component';
import { TasksListComponent } from './tasks/components/tasks-created/components/tasks-list/tasks-list.component';
import { TasksCreateComponent } from './tasks/components/tasks-create/tasks-create.component';
import { TaskTemplateTextComponent } from './tasks/components/tasks-create/components/text/text.component';
import { TaskTemplateDateComponent } from './tasks/components/tasks-create/components/date/date.component';
import { TasksTemplatePhotoComponent } from './tasks/components/tasks-create/components/photo/photo.component';
import { PhotoModalComponent } from './tasks/components/tasks-create/components/photo/modal/photo-modal.component';
import { TasksCreatedComponent } from './tasks/components/tasks-created/tasks-created.component';
import { ResponseViewComponent } from './tasks/components/response-view/response-view.component';
import { QuestionPhotoViewComponent } from './tasks/components/response-view/components/photo/question-photo-view';
import { QuestionTextViewComponent } from './tasks/components/response-view/components/text/question-text-view.component';
import { AssignedTasksListComponent } from './tasks/components/tasks-assigned/components/tasks-list/assigned-tasks-list.component';
import { ResponseQuestionsComponent } from './tasks/components/tasks-assigned/components/response-questions/response-questions.component';
import { QuestionsToResponseComponent } from './tasks/components/tasks-assigned/components/questions/questions.component';
import { TaskResponseTextComponent } from './tasks/components/tasks-assigned/components/components/text/question-text.component';
import { TasksResponsePhotoComponent } from './tasks/components/tasks-assigned/components/components/photo/question-photo.component';
import { ResponsePhotoModalComponent } from './tasks/components/tasks-assigned/components/components/photo/modal/response-photo-modal.component';
import { QuestionSingleSelectViewComponent } from './tasks/components/tasks-assigned/components/components/single-select/question-single-select-view';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { EnterpriseGuard } from './polls/guards/enterprise.guard';

const DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,

    DialogComponent,
    // NgxIndexedDBModule.forRoot(dbConfig),

    BreadCrumbComponent,
    // v2
    PollsComponent,
    ClientsComponent,
    // ClientPollComponent,
    HeaderUserComponent,
    QuestionComponent,
    SelectionComponent,
    PricedropdownComponent,
    InfoextraComponent,
    MessageInitComponent,
    MessageFinishComponent,
    B2bloginComponent,
    DialogImageHelpComponent,
    NoSanitizePipe,
    MultipleComponent,
    TextComponent,
    LoadfileComponent,
    ScaleComponent,
    NumberComponent,
    //  RankingComponent,
    DashboardPollComponent,
    DashboardPollGoalsComponent,
    RankingmvComponent,
    //  Loadfile2Component,
    FilePreviewContainerComponent,
    FilePreviewItemComponent,
    RefreshIconComponent,
    CloseIconComponent,
    PreviewLightboxComponent,
    DateComponent,
    DatetimeComponent,
    DashboardPeriodPollComponent,
    ConfirmComponent,
    SyncComponent,
    InitComponent,
    DialogErrorComponent,
    CoolerComponent,
    CoolerLoadfileComponent,
    FiltersMenuComponent,
    MultipleFilesComponent,
    ModalViewPreviewComponent,
    ClientAllComponent,
    MapsComponent,
    SelectRouteComponent,
    FiltersMenuInClientsComponent,
    AutloginComponent,
    FilesWebcamComponent,
    ModalsComponent,
    IndependentMapsComponent,
    //V2
    HeaderV2Component,
    NavbarComponent,
    SynchronizationComponent,
    HomeComponent,
    PublishedPollsComponent,
    TasksComponent,
    TasksAssignedComponent,
    TasksCreatedComponent,
    TasksListComponent,
    TasksCreateComponent,
    TaskTemplateTextComponent,
    TaskTemplateDateComponent,
    TasksTemplatePhotoComponent,
    ResponseViewComponent,
    PhotoModalComponent,
    QuestionPhotoViewComponent,
    QuestionSingleSelectViewComponent,
    QuestionTextViewComponent,
    AssignedTasksListComponent,
    ResponseQuestionsComponent,
    QuestionsToResponseComponent,
    TaskResponseTextComponent,
    TasksResponsePhotoComponent,
    ResponsePhotoModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    DragDropModule,
    MatTableModule,
    MatCheckboxModule,
    MatDividerModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatGridListModule,
    MatButtonModule,
    MatDialogModule,
    MatTabsModule,
    MatCardModule,
    ScrollingModule,
    MatRippleModule,
    MatSelectModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxChartsModule,
    NgApexchartsModule,
    NgxSpinnerModule,
    // SocialLoginModule,
    MatBadgeModule,
    MatProgressBarModule,
    // MaterialFileInputModule
    FilePickerModule,
    MatSliderModule,
    FileDropModule,
    InfiniteScrollModule,
    MatDatepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatSidenavModule,
    MatListModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatChipsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    ServiceWorkerModule.register('sw-master.js', {
      enabled: true,
    }),
    // provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // provideAuth(() => getAuth()),
    // provideAnalytics(() => getAnalytics()),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFireMessagingModule,
    WebcamModule,
    CarouselModule.forRoot(),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    FilePickerService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    ScreenTrackingService,
    { provide: VAPID_KEY, useValue: environment.vapidKey },
    {
      provide: SERVICE_WORKER,
      useFactory: () =>
        (typeof navigator !== 'undefined' &&
          navigator.serviceWorker?.register('firebase-messaging-sw.js', {
            scope: '__',
          })) ||
        undefined,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    EnterpriseGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
